<template>
  <b-sidebar
    id="add-edit-users-sidebar"
    :visible="isAddEditUsersSidebarActive"
    bg-variant="white"
    sidebar-class="sidebar-lg"
    shadow
    backdrop
    no-header
    right
    @hidden="resetForm"
    @change="(val) => $emit('update:is-add-edit-users-sidebar-active', val)"
  >
    <template #default="{ hide }">
      <!-- Header -->
      <div
        class="
          d-flex
          justify-content-between
          align-items-center
          content-sidebar-header
          px-2
          py-1
        "
      >
        <h5 class="mb-0">Edit Users</h5>

        <feather-icon
          class="ml-1 cursor-pointer"
          icon="XIcon"
          size="16"
          @click="hide"
        />
      </div>
      <b-media no-body style="margin-left: 22px">
        <b-media-aside>
          <b-link>
            <b-img
              ref="previewEl"
              rounded
              :src="userdata.avatar"
              width="100"
              height="100"
            />
          </b-link>
          <!--/ avatar -->
        </b-media-aside>
        <b-media-body class="mt-75 ml-75">
          <b-button
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            variant="primary"
            size="sm"
            class="mb-75 mr-75"
            @click="$refs.refInputEl.click()"
          >
            <input
              accept=".jpg, .png, .gif"
              :hidden="true"
              plain
              ref="refInputEl"
              type="file"
              class="d-none"
              @input="inputImageRenderer"
            />
            <span class="d-none d-sm-inline">Upload</span>
            <feather-icon icon="EditIcon" class="d-inline d-sm-none" />
          </b-button>

          <b-card-text>Allowed JPG, GIF or PNG</b-card-text>
        </b-media-body>
      </b-media>

      <!-- BODY -->
      <validation-observer #default="{ handleSubmit }" ref="refFormObserver">
        <!-- Form -->
        <b-form
          class="p-2"
          @submit.prevent="handleSubmit(onSubmit)"
          @reset.prevent="resetForm"
        >
          <!-- Full Name -->

          <validation-provider
            #default="validationContext"
            name="Name"
            rules="required"
          >
            <b-form-group label="Name" label-for="Name">
              <b-form-input id="Name" v-model="userdata.name" autofocus trim />

              <b-form-invalid-feedback>
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>
          <validation-provider
            #default="validationContext"
            name="Email"
            rules="required"
          >
            <b-form-group label="Email" label-for="Email">
              <b-form-input
                id="Email"
                @input="getUsers"
                v-model="userdata.email"
                autofocus
                trim
              />

              <b-form-invalid-feedback>
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
              <small class="text-danger">{{ wrongEmailAlert }}</small>
            </b-form-group>
          </validation-provider>
          <b-form-group label="Primary Color" label-for="Primary Color">
            <!-- {{userdata.primaryColor}} -->
            <b-input-group>
              <!-- <b-form-input id="Primary-Color" v-model="userdata.primaryColor" autofocus trim /> -->

              <b-form-input
                id="Primary-Color"
                v-model="userdata.primaryColor"
              />
              <b-input-group-append>
                <b-button variant="outline-primary" @click="selectPrimaryColor">
                  Select Color
                </b-button>
              </b-input-group-append>
            </b-input-group>
            <div v-if="primaryColorCondition">
              <color-picker
                theme="light"
                :color="primaryColor"
                :sucker-hide="false"
                :sucker-canvas="suckerCanvas"
                :sucker-area="suckerArea"
                @changeColor="changeColor"
                @openSucker="openSucker"
              />
            </div>

            <!-- </validation-provider> -->
          </b-form-group>
          <b-form-group label="Secondary Color" label-for="Secondary Color">
            <!-- {{userdata.primaryColor}} -->
            <b-input-group>
              <!-- <b-form-input id="Primary-Color" v-model="userdata.primaryColor" autofocus trim /> -->

              <b-form-input
                id="Secondary-Color"
                v-model="userdata.secondaryColor"
              />
              <b-input-group-append>
                <b-button
                  variant="outline-primary"
                  @click="selectSecondaryColor"
                >
                  Select Color
                </b-button>
              </b-input-group-append>
            </b-input-group>
            <div v-if="secondaryColorCondition">
              <color-picker
                theme="light"
                :color="secondaryColor"
                :sucker-hide="false"
                :sucker-canvas="SecondaryuckerCanvas"
                :sucker-area="secondarySuckerArea"
                @changeColor="secondaryChangeColor"
                @openSucker="secondaryOpenSucker"
              />
            </div>

            <!-- </validation-provider> -->
          </b-form-group>
          <!-- userdata.tretiaryColor -->
          <b-form-group label="Tertiary Color" label-for="Tertiary Color">
            <b-input-group>
              <b-form-input
                id="Tertiary-Color"
                v-model="userdata.tretiaryColor"
              />
              <b-input-group-append>
                <b-button variant="outline-primary" @click="selectThirdColor">
                  Select Color
                </b-button>
              </b-input-group-append>
            </b-input-group>
            <div v-if="thirdColorCondition">
              <color-picker
                theme="light"
                :color="thirdColor"
                :sucker-hide="false"
                :sucker-canvas="thirduckerCanvas"
                :sucker-area="thirdSuckerArea"
                @changeColor="thirdChangeColor"
                @openSucker="thirdOpenSucker"
              />
            </div>
          </b-form-group>
          <validation-provider name="Role" v-slot="validationContext">
            <b-form-group
              id="example-input-group-3"
              label="Role"
              label-for="example-input-3"
            >
              <b-form-select
                id="example-input-3"
                name="example-input-3"
                v-model="selected"
                :options="table_data"
                v-on:change="checkRole(selected)"
                aria-describedby="input-3-live-feedback"
              ></b-form-select>

              <b-form-invalid-feedback id="input-3-live-feedback">{{
                validationContext.errors[0]
              }}</b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>
          <!-- <b-alert v-if="showClasses == true" variant="primary" show>
            <div class="alert-body">
              <span
                ><strong>Note:</strong>Leave Classes field for all
                classes.</span
              >
            </div>
          </b-alert> -->
          <!-- {{classCheckbox}} -->
          <div v-if="classCheckbox == true">
            <b-form-checkbox
              v-on:change="hideShowClassesSubject(allClasses)"
              v-model="allClasses"
            >
              All Classes
            </b-form-checkbox>
          </div>
          <validation-provider
            name="Classes"
            v-slot="validationContext"
            v-if="showClasses == true"
          >
            <b-form-group
              id="example-input-group-3"
              label="Classes"
              label-for="example-input-3"
            >
              <v-select
                multiple
                :close-on-select="false"
                v-model="selectedClasses"
                :options="classes_data"
                @input="checkclasses(selectedClasses, '1')"
                label="className"
                input-id="add-class"
              >
              </v-select>
              <!-- <b-form-select
                id="example-input-3"
                name="example-input-3"
                aria-describedby="input-3-live-feedback"
              ></b-form-select> -->

              <b-form-invalid-feedback id="input-3-live-feedback">{{
                validationContext.errors[0]
              }}</b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>
          <!-- <h6
            style="font-size: 12px; background-color: grey; color: black"
            v-if="showSubjects == true"
          >
            Note:if Subjects field is empty it can allows all permissions of the
            subjects selected class.
          </h6> -->
          <!-- <b-alert v-if="showSubjects == true" variant="primary" show>
            <div class="alert-body">
              <span
                ><strong>Note:</strong>Leave Subjects field for all
                subjects.</span
              >
            </div>
          </b-alert> -->
          <div v-if="subjectCheckbox == true">
            <b-form-checkbox
              v-on:change="hideShowSubject(allSubject)"
              v-model="allSubject"
            >
              All Subject
            </b-form-checkbox>
          </div>
          <b-form-group
            v-if="showSubjects == true"
            label="Subjects"
            label-for="Subjects"
          >
            <v-select
              v-model="selectedSubject"
              multiple
              :close-on-select="false"
              :options="subjects_data"
              label="subjectName"
              input-id="add-subjects"
            >
            </v-select>
          </b-form-group>
        </b-form>
      </validation-observer>
    </template>
    <template #footer="{ hide }">
      <div class="d-flex bg-light text-light align-items-center px-1 py-2">
        <!-- <div > -->
        <b-button
          v-ripple.400="'rgba(255, 255, 255, 0.15)'"
          variant="primary"
          type="submit"
          @click="onSubmit"
          :disabled="submitbutton"
        >
          Update
        </b-button>
        <b-button
          v-ripple.400="'rgba(186, 191, 199, 0.15)'"
          class="ml-2"
          type="button"
          variant="outline-secondary"
          @click="hide"
        >
          Cancel
        </b-button>
      </div>
      <!-- </div> -->
    </template>
  </b-sidebar>
</template>

<script>
import {
  BSidebar,
  BForm,
  BFormGroup,
  BFormInput,
  BFormInvalidFeedback,
  BButton,
  BCardText,
  BMedia,
  BMediaAside,
  BMediaBody,
  BLink,
  BImg,
  BAlert,
  BCol,
  BFormFile,
  BFormCheckbox,
  BFormSelect,
  BInputGroupAppend,
   BInputGroup,
} from "bootstrap-vue";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import { ref } from "@vue/composition-api";
import { required, alphaNum, email } from "@validations";
import { togglePasswordVisibility } from "@core/mixins/ui/forms";
import formValidation from "@core/comp-functions/forms/form-validation";
import Ripple from "vue-ripple-directive";
import vSelect from "vue-select";
import countries from "../../../../@fake-db/data/other/countries";
import store from "@/store";
import axios from "axios";
import S3 from "aws-s3";
import colorPicker from "@caohenghu/vue-colorpicker";

import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
export default {
  components: {
    BSidebar,
    BCol,
    BCardText,
    BMedia,
    BMediaAside,
    BMediaBody,
    colorPicker,
    BLink,
    BFormCheckbox,
    BImg,
    BAlert,
    BForm,
    BFormGroup,
     BInputGroup,
    BFormSelect,
    BFormInput,
    BFormInvalidFeedback,
    BButton,
    vSelect,
    BFormFile,
    BInputGroupAppend,
    ToastificationContent,
    // Form Validation
    ValidationProvider,
    ValidationObserver,
  },
  directives: {
    Ripple,
  },
  model: {
    prop: "isAddEditUsersSidebarActive",
    event: "update:is-add-edit-users-sidebar-active",
  },
  props: {
    isAddEditUsersSidebarActive: {
      type: Boolean,
      required: true,
    },
    editDataProps: {
      type: Object,
      required: true,
    },
  },
  watch: {
    isAddEditUsersSidebarActive(val) {
      var cloneClassData = JSON.parse(JSON.stringify(this.classes_data));
      if (this.editDataProps.avatar != null) {
        this.userdata.avatar = this.editDataProps.avatar;
      } else {
        this.userdata.avatar =
          "https://t4.ftcdn.net/jpg/02/07/87/79/360_F_207877921_BtG6ZKAVvtLyc5GWpBNEIlIxsffTtWkv.jpg";
      }
      if(this.editDataProps.first){
          this.userdata.primaryColor = this.editDataProps.first;
      }
      if(this.editDataProps.second){
           this.userdata.secondaryColor = this.editDataProps.second;
      }
      if(this.editDataProps.third){
          this.userdata.tretiaryColor = this.editDataProps.third;
      }

      if (this.editDataProps.roleName == "admin") {
        this.selected = this.editDataProps.roleName;
      } else {
        this.selected = this.editDataProps.roleName;
        var newArrayClassData = [];
        if (this.editDataProps.classAccess) {
          for (let index = 0; index < cloneClassData.length; index++) {
            for (let i = 0; i < this.editDataProps.classAccess.length; i++) {
              if (
                cloneClassData[index]._id == this.editDataProps.classAccess[i]
              ) {
                newArrayClassData[index] = cloneClassData[index];
              }
            }
          }
          var filtered = newArrayClassData.filter(function (el) {
            return el != null;
          });
          this.selectedClasses = filtered;
          this.showClasses = true;
          this.checkclasses(this.selectedClasses, "2");
        } else {
          this.checkRole(this.editDataProps.roleName);
        }
      }
      this.userdata.name = this.editDataProps.name;
      this.userdata.email = this.editDataProps.email;
    },
  },
  mixins: [togglePasswordVisibility],
  data() {
    return {
      required,
      alphaNum,
      email,
      countries,
      primaryColorCondition: false,
      suckerCanvas: null,
      primaryColor: "#59c7f9",
      suckerArea: [],
      isSucking: false,
      secondaryColor: "#59c7f9",
      secondarySuckerArea: [],
      isSecondarySucking: false,
      secondaryColorCondition: false,
      SecondaryuckerCanvas: null,
      thirdColor: "#59c7f9",
      thirdSuckerArea: [],
      isthirdSucking: false,
      thirdColorCondition: false,
      thirduckerCanvas: null,
      table_data: [],
      selected: "",
      subjectCheckbox: false,
      selectedClasses: [],
      selectedSubject: [],
      classes_data: [],
      classCheckbox: false,
      subjects_data: [],
      allClasses: false,
      showClasses: false,
      showSubjects: false,
      userdata: {
        avatar: "",
        name: "",
        email: "",
        primaryColor: "",
        secondaryColor: "",
        tretiaryColor: "",
      },
      allSubject: false,
      add_new_level_data: "",
      alluser: "",
      class: "",
      wrongEmailAlert: "",
    };
  },
  // created() {
  //   console.log(this.addlevelclassid);
  // },
  computed: {
    passwordToggleIcon() {
      return this.passwordFieldType === "password" ? "EyeIcon" : "EyeOffIcon";
    },
    submitbutton() {
      return !(this.userdata.name.length > 0 &&
      this.userdata.email.length > 0 &&
      this.userdata.primaryColor.length > 0 &&
      this.userdata.secondaryColor.length > 0 &&
      this.userdata.tretiaryColor.length > 0 &&
      this.selected === "admin"
        ? true
        : this.selected.length > 0 && this.allClasses == true
        ? true
        : this.selectedClasses.length > 0 && this.allSubject == true
        ? true
        : this.selectedSubject.length > 0);
    },
  },
  created() {
    this.gettabledata();
  },
  methods: {
    selectThirdColor() {
      this.thirdColorCondition = !this.thirdColorCondition;
    },
    thirdChangeColor(color) {
      const { r, g, b, a } = color.rgba;
      // console.log('asfas',color);
      this.userdata.tretiaryColor = color.hex;
      this.thirdColorCondition = false;
      this.thirdColor = `rgba(${r}, ${g}, ${b}, ${a})`;
    },
    thirdOpenSucker(isOpen) {
      if (isOpen) {
      } else {
      }
    },
    selectSecondaryColor() {
      this.secondaryColorCondition = !this.secondaryColorCondition;
    },
    secondaryChangeColor(color) {
      const { r, g, b, a } = color.rgba;
      // console.log('asfas',color);
      this.userdata.secondaryColor = color.hex;
      this.secondaryColorCondition = false;
      this.secondaryColor = `rgba(${r}, ${g}, ${b}, ${a})`;
    },
    secondaryOpenSucker(isOpen) {
      if (isOpen) {
      } else {
      }
    },
    changeColor(color) {
      const { r, g, b, a } = color.rgba;
      // console.log('asfas',color);
      this.userdata.primaryColor = color.hex;
      this.primaryColor = `rgba(${r}, ${g}, ${b}, ${a})`;
      this.primaryColorCondition = false;
    },
    openSucker(isOpen) {
      if (isOpen) {
      } else {
      }
    },
    selectPrimaryColor() {
      this.primaryColorCondition = !this.primaryColorCondition;
    },
    hideShowSubject(value) {
      if (value) {
        this.showSubjects = false;
      } else {
        this.showSubjects = true;
        this.selectedSubject = [];
      }
    },
    hideShowClassesSubject(value) {
      // console.log('value',value);
      if (value) {
        this.showClasses = false;
        this.showSubjects = false;
        this.subjectCheckbox = false;
        this.selectedClasses = [];
      } else {
        this.showClasses = true;
      }
    },
    getUsers() {
      this.wrongEmailAlert = "The Email field must be a valid email";
      // console.log(
      //   "this.userdata.emailthis.userdata.email",
      //   this.userdata.email
      // );
      // console.log(".userdata.email", this.userdata.email.length);
      if (this.ValidateEmail(this.userdata.email)) {
        // console.log("welcome to for loop");
        let arr = [];
        for (let index = 0; index < this.alluser.length; index++) {
          //  console.log("this.alluser[index]",this.alluser[index])
          // console.log("this.alluser[index].email",this.userdata.email)
          if (this.userdata.email == this.alluser[index].email) {
            arr.push("not valid");
            return this.validated();
            // console.log();
          } else {
            arr.push("valid");

            // console.log("valid");
          }
        }
        return this.notvalidated();
      }
    },
    validated() {
      this.wrongEmailAlert = "Email has already been taken!";
      // this.userdata.email = "";

      // console.log("123");
    },
    notvalidated() {
      // ;
      // console.log("this.ValidateEmail(mail)", this.ValidateEmail(this.userdata.email));
      // if (this.ValidateEmail(this.userdata.email)) {
      this.wrongEmailAlert = "";
      // } else {
      //   this.wrongEmailAlert = "The Email field must be a valid email";
      //   this.userdata.email = "";
      // }
    },
    ValidateEmail(mail) {
      // console.log("mail",mail)
      if (/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(mail)) {
        return true;
      }
      // alert("You have entered an invalid email address!");
      return false;
    },
    resetForm() {
      // this.editDataProps = {};
      // console.log("reset");
      this.selected = "";
      this.selectedClasses = [];
      this.selectedSubject = [];
      // this.classes_data = [];
      this.subjects_data = [];
      this.showClasses = false;
      this.allClasses = false;
      this.showSubjects = false;
      // this.allSubject = false;
      this.subjectCheckbox = false;
      this.classCheckbox = false;
      this.userdata = {
        avatar: "",
        name: "",
        email: "",
        primaryColor: "",
        secondaryColor: "",
        tretiaryColor: "",
      };
      (this.primaryColor = "#59c7f9"),
        (this.suckerArea = []),
        (this.isSucking = false),
        (this.secondaryColor = "#59c7f9"),
        (this.secondarySuckerArea = []),
        (this.isSecondarySucking = false),
        (this.secondaryColorCondition = false),
        (this.SecondaryuckerCanvas = null),
        (this.thirdColor = "#59c7f9"),
        (this.thirdSuckerArea = []),
        (this.isthirdSucking = false),
        (this.thirdColorCondition = false),
        (this.thirduckerCanvas = null);
    },
    checkclasses(classData, Parm) {
      this.classCheckbox = true;
      if (classData.length > 0) {
        if (Parm == "1") {
          this.selectedSubject = [];
          let classClone = [];
          classClone = JSON.parse(JSON.stringify(classData));
          this.subjects_data = [];
          for (let i = 0; i < classClone.length; i++) {
            var classNAME = "";
            classNAME = classClone[i].className;
            for (let j = 0; j < classClone[i].subjects.length; j++) {
              classClone[i].subjects[j].subjectName =
                classNAME + "-" + classClone[i].subjects[j].subjectName;
              this.subjects_data.push(classClone[i].subjects[j]);
            }
          }
          this.subjectCheckbox = true;
          this.allSubject = false;
          // this.subjects_data.push({ subjectName: "All subjects" });
          this.showSubjects = true;
        } else {
          // console.log("all okay")
          // console.log("classData", classData);
          let classClone = [];
          classClone = JSON.parse(JSON.stringify(classData));
          this.subjects_data = [];
          this.selectedSubject = [];

          // console.log("classClone", classClone);
          for (let i = 0; i < classClone.length; i++) {
            var classNAME = "";
            classNAME = classClone[i].className;
            for (let j = 0; j < classClone[i].subjects.length; j++) {
              classClone[i].subjects[j].subjectName =
                classNAME + "-" + classClone[i].subjects[j].subjectName;
              this.subjects_data.push(classClone[i].subjects[j]);
            }
          }
          // this.subjects_data.push({ subjectName: "All subjects" });
          for (let index = 0; index < this.subjects_data.length; index++) {
            // alert(0)
            // console.log("this.editDataProps.subjectAccess",this.editDataProps.subjectAccess);
            for (let i = 0; i < this.editDataProps.subjectAccess.length; i++) {
              if (
                this.subjects_data[index]._id ==
                this.editDataProps.subjectAccess[i]
              ) {
                this.selectedSubject.push(this.subjects_data[index]);
                // console.log("this.selectedSubject",this.subjects_data[index])
              }
            }
          }
          this.subjectCheckbox = true;
          //
          this.showSubjects = true;
          this.allSubject = false;
        }
      } else {
        this.showSubjects = false;
        this.subjectCheckbox = false;
      }
    },
    checkRole(i) {
      // console.log("new index", i);
      if (i == "admin") {
        this.showClasses = false;
        (this.showSubjects = false), (this.subjectCheckbox = false);
        (this.selectedClasses = ""), (this.selectedSubject = "");
        this.classCheckbox = false;
      } else {
        this.classCheckbox = true;
        this.allClasses = false;

        // this.classes_data.push({ className: "All classes" });
        this.showClasses = true;
      }
    },
    gettabledata: function () {
      axios
        .get(process.env.VUE_APP_API_URL + "/roles/data/all", {
          headers: {
            Authorization: "Bearer " + localStorage.getItem("_t"),
          },
        })
        .then((get_response) => {
          var roleDAta = get_response.data;
          // console.log("this.roledat", roleDAta);
          for (let index = 0; index < roleDAta.length; index++) {
            this.table_data.push(roleDAta[index]["name"]);
          }
          // console.log("this.table_data", this.table_data);
        });
      axios
        .get(process.env.VUE_APP_API_URL + "/classes_data")
        .then((get_response) => {
          this.class = get_response.data;
          var data = get_response.data;
          for (let i = 0; i < data.length; i++) {
            this.classes_data.push(data[i]);
          }
        });
      axios
        .get(process.env.VUE_APP_API_URL + "/cms/users/data", {
          headers: {
            Authorization: "Bearer " + localStorage.getItem("_t"),
          },
        })
        .then((get_response) => {
          this.alluser = get_response.data;
        })
        .catch(function (error) {
          console.log("error", error);
        });
    },
    getValidationState({ dirty, validated, valid = null }) {
      return dirty || validated ? valid : null;
    },

    onSubmit() {
      this.email_check(this.userdata.email);
      if (this.email_check(this.userdata.email) == null) {
        this.showToast("danger");
      } else {
        this.userdata.id = this.editDataProps.id;
        if (this.selected == "admin") {
          this.userdata.roleName = this.selected;
        } else {
          this.userdata.roleName = this.selected;
          if (this.allClasses) {
            this.userdata.className = JSON.parse(
              JSON.stringify(this.classes_data)
            );
            this.allSubject = true;
            if (this.allSubject) {
              var subjectArr = [];
              for (let i = 0; i < this.classes_data.length; i++) {
                if (this.classes_data[i].subjects) {
                  for (
                    let j = 0;
                    j < this.classes_data[i].subjects.length;
                    j++
                  ) {
                    //  const element = array[j];
                    subjectArr.push(this.classes_data[i].subjects[j]);
                  }
                }
              }
              this.userdata.subjects = JSON.parse(JSON.stringify(subjectArr));
            }
          } else {
            this.userdata.className = JSON.parse(
              JSON.stringify(this.selectedClasses)
            );
            if (this.allSubject) {
              this.userdata.subjects = JSON.parse(
                JSON.stringify(this.subjects_data)
              );
            } else {
              this.userdata.subjects = JSON.parse(
                JSON.stringify(this.selectedSubject)
              );
            }
          }
          for (let index = 0; index < this.userdata.subjects.length; index++) {
            // if(this.userdata.subjects.subjectName == 'All subjects'){
            //   delete this.userdata.subjects;
            // }
            delete this.userdata.subjects[index].category;
          }
          for (let index = 0; index < this.userdata.className.length; index++) {
            delete this.userdata.className[index].subjects;
          }
        }

        this.add_new_user_data = this.userdata;
        // console.log("this.add_new_user_data",this.add_new_user_data);
        this.$emit("clickEditUser", this.add_new_user_data);
        this.$root.$emit("bv::toggle::collapse", "add-edit-users-sidebar");
        // this.showToas("success");
      }
      //   console.log("xxxxxxxxxxx  ",this.email_check(this.userdata.email));

      //
    },
    email_check(email) {
      return String(email)
        .toLowerCase()
        .match(
          /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
        );
    },
    showToas(variant) {
      this.$toast({
        component: ToastificationContent,
        props: {
          title: "Notification",
          icon: "BellIcon",
          text: "form  submit",
          variant,
        },
      });
    },
    inputImageRenderer(input) {
      if (input.target.files && input.target.files[0]) {
        const config = {
          bucketName: "zape02",
          region: "ap-southeast-1",
          accessKeyId: "AKIA2ANCMLDFMDJOHVXY",
          secretAccessKey: "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",
          s3Url: "https://zape02.s3.ap-southeast-1.amazonaws.com",
          visibility: "public",
        };
        const S3Client = new S3(config);
        const image =
          Math.random().toString() +
          Math.random().toString() +
          Math.random().toString();
        S3Client.uploadFile(input.target.files[0], image)
          .then((data) => (this.userdata.avatar = data.location))
          .catch((err) => console.error(err));
      }
    },
    showToast(variant) {
      this.$toast({
        component: ToastificationContent,
        props: {
          title: "Notification",
          icon: "BellIcon",
          text: "Please enter a valid email",
          variant,
        },
      });
    },
  },
};
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";

#add-new-user-sidebar {
  .vs__dropdown-menu {
    max-height: 200px !important;
  }
}
</style>
